import { TableContainer } from "components/tables/server-table/TableContainer";
import { useRouter } from "next/router";
import { useTable, usePagination, useSortBy } from "react-table";
import { classNames } from "util/common";
import { TablePagination } from "./TablePagination";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import { EmptyPlaceholder } from "../EmptyPlaceholder";
import { ViewGridAddIcon } from "@heroicons/react/outline";
import { WarningBox } from "../../layout/WarningBox";
import React, {
	Fragment,
	useCallback,
	useContext,
	useEffect,
	useLayoutEffect,
	useRef,
	useState,
} from "react";
import { GridLayout } from "../GridLayout";
import { getPayloadFromExpandedRow } from "../../../util/analytics";
import { AnalyticsContext } from "../../contexts/AnalyticsProvider";
import { useIsInIframe } from "components/hooks/useIsInIframe";
import { WBO } from "util/types";
import useWindowDimensions from "components/hooks/useWindowDimensions";
import { scrollPositionAtom } from "components/jotai/scrollPositionAtom";
import { useAtom } from "jotai";
import ProductViewModal from "components/modals/ProductViewModal";
import LoadingAnimation from "components/ui/LoadingAnimation";
import {
	getPharmacyImpressionGoogleEvent,
	getPharmacyRowExpandedGoogleEvent,
	getProductsImpressionGoogleEvent,
} from "util/analytics/handlers";
const LAYOUT = { GRID: "grid", LIST: "list" };
import { InView } from "react-intersection-observer";
import { debounce } from "lodash";
import { CacheContext } from "components/contexts/CacheContext";

export function ServerTable({
	adType = "filter",
	blockAtEndOfRow = false,
	cart = null,
	columns,
	customActionButton = null,
	customButtons = null,
	customNoItemsMessage = "",
	emptyPlaceholderText,
	enableOrders = false,
	filters,
	filtersTitle,
	getRowClassName = r => "",
	getRowHiddenContent = r => null,
	getRowKey = r => r.id,
	getRowLink = r => null,
	hideLayoutButton = false,
	hideOnScroll,
	hidePagination = false,
	is48HoursPast = false,
	isFiltersOpen,
	isHomeWidget,
	isOrdersEnabled = false,
	isQuickfilter = false,
	isRowExpandable = r => false,
	isShabbatPage = false,
	isDesiredCart = false,
	isSingleProduct = false,
	isSingleStore = false,
	isStoreProducts = false,
	isStoresTable = false,
	isTableView,
	isTableWithMap = false,
	isWithDiscountsFilter = false,
	isWithLocationButton,
	isWithOpenFilter = false,
	isWithShippingFilter = false,
	itemId,
	maxTableHeight,
	naked = false,
	onAddClick = () => false,
	onRowClick: onRowClickProp = () => {},
	plainHeader = false,
	prependContent,
	prependSortByContent,
	prependStoreContent,
	prependStoreSortByContent,
	searchPlaceholder = "חיפוש...",
	searchable = false,
	selectable = false,
	setIsTableView,
	showAds = false,
	showDuplicates = false,
	showExpandedRowArrows = true,
	showPriceRange = false,
	subContent,
	subtitle,
	tabletMode = false,
	tbl, // useServerTable instance.
	title,
	withEmptyPlaceholder = false,
	withMap,
	isMainTable = false,
	...props
}) {
	const router = useRouter();
	const cache = useContext(CacheContext);
	const { query } = router;
	const isInIframe = useIsInIframe();
	const { width } = useWindowDimensions();
	const { collectAnalytics, collectAnalyticsGoogle } =
		useContext(AnalyticsContext);
	const isAllProductsPage = router.asPath.includes("/products/");
	const isStoresPage = router.asPath.includes("/stores/");
	const isDesiredCartPage = router.asPath.includes("/desired-cart/");
	const [fetchingNextPage, setFetchingNextPage] = useState(false);
	const [searchQuery, setSearchQuery] = useState(tbl.searchQuery || "");
	const [expandedRowId, setExpandedRowId] = useState(null);
	const [isFirstLoad, setIsFirstLoad] = useState(true);
	const [expandedRowIndex, setExpandedRowIndex] = useState(null);

	const [fetchMore, setFetchMore] = useState(false);
	const [scrollPosition, setScrollPosition] = useAtom(scrollPositionAtom);
	const [loadingNextPage, setLoadingNextPage] = useState(false);
	const seenRows = useRef(new Set());
	const withGridLayout = tbl.layoutOptions.some(
		option => option === LAYOUT.GRID,
	);
	const handleSendEvent = () => {
		if (tbl.layout !== "grid") {
			const eventRows = [...seenRows.current];

			if (eventRows.length > 0) {
				const productsEvent = getProductsImpressionGoogleEvent(
					router.asPath,
					eventRows,
					isInIframe,
					router,
					cache,
				);

				const storesEvent = getPharmacyImpressionGoogleEvent(
					router.asPath,
					eventRows,
					isInIframe,
				);

				if (productsEvent) {
					console.log("productsEvent regular");
					collectAnalyticsGoogle(productsEvent);
				} else if (storesEvent) {
					console.log("storesEvent");
					collectAnalyticsGoogle(storesEvent);
				}
			}
			seenRows.current = new Set();
		}
	};

	useEffect(() => {
		// Reset scroll position after filter/search/sort
		// Two reasons for this: 1. It's weird not scrolling after u filter/sort/search 2. Intersection observer is not detecting stuff properly
		if (tableRef.current && tableContainerRef.current) {
			tableRef.current.scrollTo(0, 0);
			tableContainerRef.current.scrollTo(0, 0);
		}
	}, [tbl.filters, tbl.sortBy, tbl.searchQuery]);

	useEffect(() => {
		// On unmount, send all the items that were seen to event and then reset the ref
		// I use ref because with state the value is "stale" because of the closure
		return () => {
			handleSendEvent();
		};
	}, [tbl.filters, tbl.sortBy, tbl.searchQuery, tbl.layout, isInIframe]);

	useEffect(() => {
		// Also send the event on beforeunload incase someone closes the tab/window
		window.addEventListener("beforeunload", handleSendEvent);

		return () => {
			window.removeEventListener("beforeunload", handleSendEvent);
		};
	}, [tbl.filters, tbl.sortBy, tbl.searchQuery]);

	const [modalViewProduct, setModalViewProduct] = useState({
		isOpen: false,
		item: null,
		history: [],
	});

	const {
		loading,
		error,
		items,
		pageNum,
		selectedIndexes,
		onRowSelectionChanged,
		onHeaderSelectionChanged,
		infiniteScrolling,
	} = tbl;
	const isRowSelected = idx => selectedIndexes.indexOf(idx) !== -1;
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize, sortBy },
	} = useTable(
		{
			columns,
			data: items,
			manualPagination: true,
			manualSortBy: true,
			autoResetPage: false,
			pageCount: tbl.totalPages,
			initialState: {
				pageIndex: tbl.pageNum - 1,
				pageSize: tbl.pageSize,
			},
		},
		useSortBy,
		usePagination,
	);

	useEffect(() => {
		const n = pageIndex + 1;
		tbl.gotoPage(n);
	}, [pageIndex]);

	const debounceSortBy = useCallback(
		debounce(sb => tbl.changeSortBy(sb), 500),
		[tbl],
	);

	useEffect(() => {
		if (!tbl.ready) {
			return;
		}

		const sb = sortArr(sortBy || []);

		debounceSortBy(sb);
	}, [sortBy]);

	const tableRef = useRef();

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [tbl.totalPages]);

	useEffect(() => {
		if (!fetchingNextPage) {
			return;
		}
		if (!infiniteScrolling) {
			return;
		}
		if (!canNextPage) {
			return;
		}
		if (loadingNextPage) return;
		setLoadingNextPage(true);
		tbl.gotoNextPage().then(res => {
			setLoadingNextPage(false);
			setFetchMore(false);
		});
	}, [fetchingNextPage]);

	useEffect(() => {
		if (!infiniteScrolling || !fetchingNextPage) {
			return;
		}
		if ([null, undefined].indexOf(pageNum) !== -1) {
			return;
		}
		setFetchingNextPage(false);
	}, [pageNum]);

	useEffect(() => {
		const q = searchQuery || null;
		void tbl.changeSearchQuery(q);
		setFetchingNextPage(false);
	}, [searchQuery]);

	const isBottom = el =>
		el.getBoundingClientRect().bottom - 20 <= window.innerHeight;

	useEffect(() => {
		if (fetchMore && canNextPage) {
			setFetchingNextPage(true);
		} else {
			setFetchingNextPage(false);
		}
	}, [fetchMore, canNextPage]);

	const handleScroll = () => {
		const windowY = window.scrollY;
		setScrollPosition(windowY);
		if (!infiniteScrolling) {
			return;
		}
		if (tbl.pageNum === tbl.totalPages) {
			return;
		}
		if (!canNextPage) {
			return;
		}
		if (!!fetchingNextPage || !!loading) {
			return;
		}
		if (!tableRef || !tableRef.current) {
			return;
		}
		if (!isBottom(tableRef.current)) {
			return;
		}

		setFetchingNextPage(true);
	};

	const onPageSizeChanged = ps => {
		if (!ps || ps === pageSize) {
			return;
		}
		setPageSize(ps);
		void tbl.changePageSize(ps);
	};

	const onSearch = async q => {
		if (!searchable) {
			return;
		}
		q = q || null;
		setSearchQuery(q);
	};

	useEffect(() => {
		if (tbl.ready) {
			setIsFirstLoad(false);
		}
	}, [tbl.ready]);

	useEffect(() => {
		if (!isTableWithMap) {
			setScrollPosition(0);
		}
	}, []);

	useEffect(() => {
		if (tbl.ready) {
			window.scrollTo(0, scrollPosition);
		}
	}, [query, tbl.ready]);

	const onFiltersChanged = async values => {
		values = values || [];
		setFetchingNextPage(false);
		return await tbl.changeFilters(values);
	};

	const onRowClick = (r, index) => {
		onRowClickProp(r);
		setExpandedRowIndex(index); // Trigger the row expansion

		const link = getRowLink(r);
		if (!!link) {
			void router.push(link);
			return;
		}

		const hiddenContent = getRowHiddenContent(r);
		if (!hiddenContent) {
			return;
		}
		let k;
		if (showDuplicates) {
			k = r.store_product_id;
		} else {
			k = getRowKey(r);
		}
		if (expandedRowId === k) {
			setExpandedRowId(null);
			return;
		}
		setExpandedRowId(k);
		const { eventName, eventPayload } = getPayloadFromExpandedRow(
			r,
			router.asPath,
		);
		collectAnalytics(eventName, eventPayload);

		const event = getPharmacyRowExpandedGoogleEvent(
			r,
			router.asPath,
			isInIframe,
		);

		if (!event) {
			return;
		}

		collectAnalyticsGoogle(event);
	};

	const noItemsMessage = () => {
		if (!!loading) {
			return (
				<div className="w-full flex flex-col items-center justify-center rounded-md py-5 bg-white">
					<LoadingAnimation />
					<span>טוען...</span>
				</div>
			);
		}
		if (!!tbl.searchQuery) {
			return `אין ממצאים לחיפוש, "${tbl.searchQuery}".`;
		}
		if (!!withEmptyPlaceholder) {
			return (
				<EmptyPlaceholder
					text={emptyPlaceholderText}
					icon={<ViewGridAddIcon className="w-6 h-6" />}
					onClick={onAddClick}
				/>
			);
		}
		if (!!customNoItemsMessage) {
			return customNoItemsMessage;
		}
		return (
			<div className="w-full rounded-md py-9 shadow-sm bg-white flex justify-center items-center">
				<span>אין נתונים להצגה.</span>
			</div>
		);
	};

	const hasItems = !!items && !!items.length;
	const isAllSelected = selectedIndexes.length === items.length;

	const removeDuplicates = arr => {
		let uniquePage = [];
		if (isSingleStore) {
			uniquePage = arr.filter(
				(v, i, a) =>
					a.findIndex(
						t => t.original.store_product_id === v.original.store_product_id,
					) === i,
			);
		} else {
			uniquePage = arr.filter(
				(v, i, a) => a.findIndex(t => t.original.id === v.original.id) === i,
			);
		}
		return uniquePage;
	};

	const uniquePage = removeDuplicates(page);
	const tableContainerRef = useRef();
	const rowsRef = useRef([]);

	useLayoutEffect(() => {
		if (
			(isSingleProduct ||
				isSingleStore ||
				isAllProductsPage ||
				isStoresPage ||
				isDesiredCartPage) && // use it only on particular pages
			expandedRowIndex !== null &&
			tableContainerRef.current &&
			rowsRef.current[expandedRowIndex]
		) {
			// Use requestAnimationFrame to ensure the layout update is complete
			requestAnimationFrame(() => {
				const offsetTop = rowsRef.current[expandedRowIndex].offsetTop - 30; // 30 is the height of the table header
				tableContainerRef.current.scrollTo({
					top: offsetTop,
					behavior: "smooth",
				});
			});
		}
	}, [expandedRowIndex]);

	if (withGridLayout && tbl.layout === LAYOUT.GRID) {
		return (
			<>
				<ProductViewModal
					modalViewProduct={modalViewProduct}
					setModalViewProduct={setModalViewProduct}
					getRowHiddenContent={getRowHiddenContent}
				/>
				<GridLayout
					tbl={tbl}
					canNextPage={canNextPage}
					cart={cart}
					customActionButton={customActionButton}
					customButtons={customButtons}
					enableOrders={enableOrders}
					error={error}
					filterValues={tbl.filters}
					filters={filters}
					filtersTitle={filtersTitle}
					getRowClassName={getRowClassName}
					hasItems={hasItems}
					hideOnScroll={hideOnScroll}
					hideLayoutButton={hideLayoutButton}
					hidePagination={hidePagination}
					infiniteScrolling={infiniteScrolling}
					is48HoursPast={is48HoursPast}
					isFiltersOpen={false}
					isHomeWidget={isHomeWidget}
					isOrdersEnabled={isOrdersEnabled}
					isRowSelected={isRowSelected}
					isDesiredCart={isDesiredCart}
					isSingleProduct={isSingleProduct}
					isSingleStore={isSingleStore}
					isStoreProducts={isStoreProducts}
					isTableWithMap={isTableWithMap}
					isTableView={isTableView}
					isWithDiscountsFilter={isWithDiscountsFilter}
					isWithLocationButton={isWithLocationButton}
					isWithShippingFilter={isWithShippingFilter}
					loading={loading}
					naked={naked}
					noItemsMessage={noItemsMessage}
					onFiltersChanged={onFiltersChanged}
					onPageSizeChanged={onPageSizeChanged}
					onSearch={onSearch}
					pageCount={pageCount}
					prependContent={prependContent}
					prependSortByContent={prependSortByContent}
					prependStoreContent={prependStoreContent}
					prependStoreSortByContent={prependStoreSortByContent}
					searchPlaceholder={searchPlaceholder}
					searchQuery={tbl.searchQuery}
					searchable={searchable}
					setIsTableView={setIsTableView}
					setModalViewProduct={setModalViewProduct}
					showPriceRange={showPriceRange}
					subContent={subContent}
					subtitle={subtitle}
					tableRef={tableRef}
					title={title}
					uniquePage={uniquePage}
					withGridLayout={withGridLayout}
					isMainTable={isMainTable}
					{...props}
				/>
			</>
		);
	} else {
		return (
			<TableContainer
				tbl={tbl}
				customButtons={customButtons}
				filterValues={tbl.filters}
				filters={filters}
				hideOnScroll={hideOnScroll}
				hideLayoutButton={hideLayoutButton}
				is48HoursPast={is48HoursPast}
				isFiltersOpen={isFiltersOpen}
				isHomeWidget={isHomeWidget}
				isOrdersEnabled={isOrdersEnabled}
				isQuickfilter={isQuickfilter}
				isShabbatPage={isShabbatPage}
				isDesiredCart={isDesiredCart}
				isSingleProduct={isSingleProduct}
				isSingleStore={isSingleStore}
				isStoresTable={isStoresTable}
				isTableView={isTableView}
				isTableWithMap={isTableWithMap}
				isWithDiscountsFilter={isWithDiscountsFilter}
				isWithLocationButton={isWithLocationButton}
				isWithOpenFilter={isWithOpenFilter}
				isWithShippingFilter={isWithShippingFilter}
				naked={naked}
				onFiltersChanged={onFiltersChanged}
				onSearch={onSearch}
				plainHeader={plainHeader}
				prependContent={prependContent}
				prependSortByContent={prependSortByContent}
				prependStoreContent={prependStoreContent}
				prependStoreSortByContent={prependStoreSortByContent}
				searchPlaceholder={searchPlaceholder}
				searchQuery={tbl.searchQuery}
				searchable={searchable}
				setIsTableView={setIsTableView}
				subContent={subContent}
				subtitle={subtitle}
				title={title}
				withGridLayout={withGridLayout}
				withMap={withMap}
				itemId={itemId}
				isMainTable={isMainTable}
				{...props}
			>
				<ProductViewModal
					modalViewProduct={modalViewProduct}
					setModalViewProduct={setModalViewProduct}
					getRowHiddenContent={getRowHiddenContent}
				/>
				{!!error && (
					<div className="my-6">
						<WarningBox {...error} />
					</div>
				)}
				{!hasItems && <div className="text-jane-500">{noItemsMessage()}</div>}
				{!!hasItems && (
					<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg relative noselect">
						<div className="align-middle min-w-full overflow-x-auto">
							<div
								className={`${
									!isHomeWidget &&
									!isInIframe &&
									!tabletMode &&
									"overflow-y-scroll custom-scroll max-h-[491px] xl:max-h-[830px]"
								} rounded-md relative`}
								onScroll={e => {
									if (!isHomeWidget && !isInIframe && !tabletMode) {
										if (width < WBO["xl"]) {
											if (
												tableContainerRef.current.scrollTop + 600 >=
												tableContainerRef.current.scrollHeight
											) {
												setFetchMore(true);
											}
										}

										if (width > WBO["xl"]) {
											if (
												tableContainerRef.current.scrollTop + 900 >=
												tableContainerRef.current.scrollHeight
											) {
												setFetchMore(true);
											}
										}
									}
								}}
								ref={tableContainerRef}
								style={!!maxTableHeight ? { maxHeight: maxTableHeight } : {}}
							>
								<table
									{...getTableProps()}
									ref={tableRef}
									className="min-w-full  divide-y divide-transparent bg-gray-50 text-gray-400 border"
								>
									<thead className="select-none whitespace-nowrap sticky -top-0.5 z-[12]">
										{headerGroups.map((headerGroup, gi) => (
											<tr {...headerGroup.getHeaderGroupProps()} key={gi}>
												{!!selectable && (
													<th
														className="px-2 sm:px-3 text-center"
														key={`${gi}s`}
													>
														<input
															type="checkbox"
															className="focus:ring-jane-500 h-4 w-4 accent-jane-500 border-gray-300 rounded cursor-pointer"
															onChange={e =>
																onHeaderSelectionChanged(!!e.target.checked)
															}
															checked={isAllSelected}
														/>
													</th>
												)}

												{headerGroup.headers.map((column, hi) => (
													<th
														{...column.getHeaderProps(
															column.getSortByToggleProps(),
														)}
														key={hi}
														scope="col"
														className={classNames(
															"font-normal z-10 relative bg-gray-50",
															!!column.isSorted
																? "underline text-black"
																: "text-black",
														)}
													>
														<div
															className={classNames(
																"flex items-center text-[12px] sm:text-[13px] md:text-[14px] lg:text-[14px]",
																column?.name === "heb_name" ||
																	column?.name === "heb_name_sales" ||
																	column.name === "min_price_sales" ||
																	column.name === "diff_percent_sales" ||
																	column.name === "marketer_heb_name_sales"
																	? "flex justify-center "
																	: column?.name ===
																			"marketer_heb_name_new_ui" ||
																	  column?.name ===
																			"manufacturer_heb_name_new_ui" ||
																	  column?.name === "thc" ||
																	  column?.name === "cbd" ||
																	  column?.name === "series" ||
																	  column?.name === "origin_country" ||
																	  column?.name === "diff_percent_sales" ||
																	  column?.name === "min_price_sales" ||
																	  column?.name === "product_type" ||
																	  column?.name === "manufacturer_series" ||
																	  column?.name === "symbols" ||
																	  column?.name === "price_new_ui"
																	? " mb-0.5 xs:flex justify-center md:items-center "
																	: "",
															)}
														>
															{column.render("Header")}
															{!!column?.accessor && !column.isSorted ? (
																<div className="mt-0.5">
																	<ChevronUpIcon className="w-3 h-2 sm:mr-1 text-jane-900" />
																	<ChevronDownIcon className="w-3 h-2 sm:mr-1 text-jane-900" />
																</div>
															) : !!column.isSorted ? (
																!!column.isSortedDesc ? (
																	<ChevronDownIcon className="w-3 h-3 text-jane-500" />
																) : (
																	<ChevronUpIcon className="w-3 h-3 text-jane-500" />
																)
															) : (
																""
															)}
														</div>
													</th>
												))}
												{/* because -block-at-end-of-row- goes over sticky header - this is workaround
													we should consider to change the concept with this block 
													but for now this is just custom bg for header*/}
												<th
													className="bg-gray-50"
													key={`${gi}-blockAtEndOfRow`}
												>
													<div className="bg-gray-50 w-full"></div>
												</th>
											</tr>
										))}
									</thead>

									<tbody
										{...getTableBodyProps()}
										className="divide-y-0 divide-transparent bg-white"
									>
										{uniquePage.map((row, ri) => {
											prepareRow(row);
											const isSelected = isRowSelected(ri);
											const hiddenContent = getRowHiddenContent(
												row?.original,
												setModalViewProduct,
											);
											const link = getRowLink(row?.original);
											let isExpanded;
											if (showDuplicates) {
												isExpanded =
													expandedRowId === row?.original?.store_product_id;
											} else {
												isExpanded = expandedRowId === getRowKey(row?.original);
											}
											const getNumCells = () => {
												let ret = row.cells.length;
												if (!!selectable) {
													ret++;
												}
												if (!!hiddenContent && !link) {
													ret++;
												}
												return ret;
											};
											return (
												<InView key={ri}>
													{({ inView, ref, entry }) => {
														if (inView && !tbl.loading) {
															seenRows.current.add(row.original);
														}
														return (
															<Fragment>
																<tr
																	{...row.getRowProps()}
																	ref={row => (rowsRef.current[ri] = row)}
																	className={classNames(
																		"group text-gray-800 transition-colors",
																		!!isSelected
																			? "bg-blue-50 hover:bg-blue-50 bg-opacity-75 hover:bg-opacity-100"
																			: "hover:bg-gray-100",
																		!!hiddenContent || !!link
																			? "cursor-pointer"
																			: "",
																		getRowClassName(row?.original, isSelected),
																		ri % 2 === 0 ? "bg-white" : "",
																		"border-b-[1px]",
																		row?.original?.is_pinned &&
																			"bg-[#FEFCDC] hover:bg-[#FEFCA0]",
																	)}
																>
																	{!!selectable && (
																		<td
																			className="px-2 sm:px-3 text-center"
																			key={`${ri}s`}
																		>
																			<input
																				type="checkbox"
																				className="focus:ring-jane-500 h-4 w-4 accent-jane-500 border-gray-300 rounded cursor-pointer"
																				onChange={e =>
																					onRowSelectionChanged(
																						ri,
																						!!e.target.checked,
																					)
																				}
																				checked={isSelected}
																				onClick={e => e.stopPropagation()}
																			/>
																		</td>
																	)}

																	{row.cells.map((cell, ci) => {
																		const width =
																			!!cell.column.width &&
																			cell.column.width !== 150
																				? cell.column.width
																				: 0;
																		return (
																			<td
																				{...cell.getCellProps()}
																				key={ci}
																				width={width}
																				className={`p-0 m-0  ${
																					ri !== uniquePage.length - 1 &&
																					"border-b-[1px]"
																				}`}
																				onClick={() => {
																					if (cell.column?.disableExpand) {
																						return;
																					}
																					if (showDuplicates) {
																						(cell.column.id !== "add_to_cart" ||
																							row?.original
																								?.store_product_id !==
																								expandedRowId) &&
																							onRowClick(row?.original, ri);
																					} else {
																						(cell.column.id !== "add_to_cart" ||
																							row?.original?.id !==
																								expandedRowId) &&
																							onRowClick(row?.original, ri);
																					}
																				}}
																			>
																				{ci === 0 && <span ref={ref}></span>}
																				{cell.render("Cell")}
																			</td>
																		);
																	})}

																	{hiddenContent &&
																	isRowExpandable(row?.original) ? (
																		<td
																			className={`${
																				ri !== uniquePage.length - 1 &&
																				"border-b-[1px]"
																			}`}
																		>
																			{isExpanded ? (
																				<ChevronUpIcon
																					className="w-6 h-6 text-gray-400 mr-1"
																					onClick={() =>
																						onRowClick(row?.original, ri)
																					}
																				/>
																			) : (
																				<ChevronDownIcon
																					className="w-6 h-6 text-gray-400 mr-1"
																					onClick={() =>
																						onRowClick(row?.original, ri)
																					}
																				/>
																			)}
																		</td>
																	) : (
																		<td
																			role="cell"
																			className="p-0 m-0 border-b-[1px]"
																		>
																			<div
																				className={
																					blockAtEndOfRow
																						? "w-6 h-6 text-gray-400 mr-1"
																						: ""
																				}
																			/>
																		</td>
																	)}
																</tr>
																{!!isExpanded && !!hiddenContent && (
																	<tr>
																		<td colSpan={getNumCells()} className="p-0">
																			<div className="shadow-inner text-black border-b-2 px-2 py-2.5 bg-white border-gray-100">
																				{hiddenContent}
																			</div>
																		</td>
																	</tr>
																)}
															</Fragment>
														);
													}}
												</InView>
											);
										})}
									</tbody>
								</table>
							</div>
						</div>
						{!hidePagination && !infiniteScrolling && pageCount > 1 && (
							<div>
								<TablePagination
									pageCount={pageCount}
									pageIndex={pageIndex}
									pageSize={pageSize}
									canPreviousPage={canPreviousPage}
									canNextPage={canNextPage}
									onNextClicked={nextPage}
									onPrevClicked={previousPage}
									onPageNumClicked={pn => gotoPage(pn - 1)}
									onPageSizeChanged={onPageSizeChanged}
									disabled={!!loading}
								/>
							</div>
						)}
						{!!loading && (
							<>
								<div className="absolute bottom-52 left-0 w-full rounded-md flex justify-center items-center">
									<LoadingAnimation />
								</div>
								<div className="absolute bg-white bottom-0 left-0 w-full h-full rounded-md bg-opacity-40 flex justify-center items-center"></div>
							</>
						)}
					</div>
				)}
			</TableContainer>
		);
	}
}

const sortArr = (sortBy = []) => {
	let ret = [];
	for (const sb of sortBy) {
		const id = !!sb.desc ? `-${sb.id}` : sb.id;
		ret.push(id);
	}
	return ret;
};
